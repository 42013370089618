#header-top-container {
  height: 48px;
  background-color: #F7FAFC;
  box-shadow: rgb(227, 232, 238) 0px -1px 0px 0px inset;
}
#header-top {
  margin: 0 auto;
  padding: 0px 0px;
  max-width: 1280px;
  height: 48px;
}
#main-container {
  height: 48px;
  max-width: 1360px;
  padding: 0px 40px;
  margin: 0 auto;
}
#header-bottom {
  height: 48px;
  box-shadow: rgb(227, 232, 238) 0px -1px 0px 0px inset;
  margin: 0 auto;
}
#page-content {
  padding: 20px;
}
.box-width-qtr {
  width: 310px;
  height: 230px;
  margin: 0 0 20px 0px;
  padding: 0px 5px;
}
.box-width-half {
  width: 615px;
  height: 230px;
  margin: 0 0 20px 0px;
  padding: 0px 5px;
}

.box-tall {
  height: 350px;
}
